.smallcontent {
  color: #c7c7c7;
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
}
.rtl .smallcontent {
  text-align: right;
}
.headercontent {
  margin-bottom: 0.25rem !important;
}
.selectButton,
.ans-btn {
  color: #fff;
  border-color: #222;
  background-color: #222;
  width: 100%;
  border-radius: 0;
  padding: 0.375rem 1.7rem;
  min-width: 167px;
}
.selectedButtonval,
.ans-btn-selected,
.ans-btn:hover,
.ans-btn:focus {
  color: #fff;
  background-color: #b99855;
  border-color: #b99855;
  box-shadow: 0 0 15px 5px #131313;
  box-shadow: none;
  color: #fff;
}
.selectButton:hover {
  color: #fff;
  background-color: #b99855;
  border-color: #b99855;
  box-shadow: 0 0 15px 5px #131313;
}
.selectButton:focus {
  box-shadow: none;
  color: #fff;
}
.selectedButtonval:focus {
  box-shadow: none;
  color: #fff;
}
.textest,
.textest:active,
.textest:focus,
.textest:focus-within {
  opacity: ".7";
  width: "100%";
  /* background: "#222"; */
  background-color: #222;
  border-radius: 0;
  /* color: #f5f5f5; */
  font-weight: 500;
  font-size: 14;
  /* line-height: 22;  */
  outline: none;
  /* margin-left: 20px; */
  box-shadow: none;
}
.textareacss,
.textareacss:active,
.textareacss:focus,
.textareacss:focus-within {
  width: "100%";
  height: "87px";
  background-color: "#222";
  font-family: "Gotham";
  line-height: "22px";
  font-weight: 500;
  color: "#a1a1a1";
  outline: none;
  box-shadow: none;
  opacity: ".7";
  border: none;
}
.usd {
  opacity: ".7";
  width: "100%";
  /* background: "#222"; */
  background-color: #222;
  border-radius: 0;
  color: #b99855;
  font-weight: 500;
  font-size: 14;
}

/*Tabs*/
.tabs-container {
  /* padding: 15px; */
  /* background: #222222; */
  width: 100%;
  display: flex;
  list-style-type: none;
  background-color: transparent;
  position: relative;
}
.active-tab {
  border-bottom: 2px solid #b99855 !important;
  color: #b99855 !important;
  font-weight: 700 !important;
}

.tab-item {
  line-height: 20px;
  font-weight: 500;
  padding: 1rem 1.5rem;
  font-size: 14px;
  cursor: pointer;
}
.modaltemporay {
  width: 100%;
  background-color: #1f1f1f;
  height: fit-content;
  position: relative;
}
.prev-tab {
}
.q-container {
  padding: 38px 4.5rem;
}
.question-wrapper {
  margin-bottom: 3rem;
}
.question-wrapper .q-text {
  margin-bottom: 5px;
  font-size: 14px;
}
.rtl .question-wrapper .q-text {
  text-align: right;
}
.question-wrapper .ans-row {
  margin-top: 20px;
  margin-bottom: 25px;
}
.question-wrapper .ans-row .ans-col {
  padding-top: 5px;
  padding-bottom: 5px;
}
.buttonContainer {
  align-items: center;
  margin-top: 60px;
  width: 100%;
}
.next-btn {
  color: #b99855;
  background-color: #1f1f1f;
  border: 1px solid #b99855;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  padding: 0.375rem 1.7rem;
  width: 100%;
  border-radius: 0;
}
.next-btn:hover {
  background-color: #b99855;
  color: #ffffff;
}
.back-btn {
  background: none;
  border: none;
  color: #b99855;
}
.enter-text {
  width: 100%;
  padding: 0.375rem 1.25rem;
  background: #222222;
  outline: none;
  border: none;
  color: #f5f5f5;
  font-size: 14px;
}
.enter-emailtext {
  width: 100%;
  padding: 0.375rem 1.25rem;
  background: #222222;
  outline: none;
  border: none;
  color: #f5f5f5;
  font-size: 14px;
}
.left-arrow {
  display: none;
}
.right-arrow {
  display: inline;
}
.rtl .left-arrow {
  display: inline;
}
.rtl .right-arrow {
  display: none;
}

/* .commentCss{
      width: "100%!important";
      height: "87px!important";
      background-Color: "#222!important";
      font-family: 'Gotham';
      line-height: "22px!important";
      font-weight: "500!important";
      color: "#alalal!important";
      box-shadow: "none";
      opacity: ".7!important" } */

.rtl .namesSection {
  text-align: right;
}

.rtl .formItem {
  text-align: right;
}

.rtl .buttonContainer {
  text-align: right;
}

.rtl h5 {
  text-align: right;
}
.rtl h6 {
  text-align: right;
  padding-right: 10px;
}
.rtl enter-amount {
  text-align: right;
}
.labeloptionstyle {
  top: 10px;
  position: absolute;
  /* left: 20px; */
  border-top: solid 1px #fff;
  padding: 10px 0 0 0;
  width: 128%;
}
.paddingleftn10 {
  left: -10px;
}
.paddingleftn25 {
  left: -25px;
}
.paddingleftn20 {
  left: -20px;
}
.paddingleft0 {
  left: 0px;
}
.lastlabeloptionstyle {
  top: 10px;
  position: absolute;
  left: -20px;
  padding: 10px 0 0 0;
  width: 100%;
}
.marginbottom75 {
  margin-bottom: 75px !important;
}
.lastcolumn {
  z-index: -1;
}
#check-api-1 {
  background-color: "#b99855";
}

.testradio,
input[type="radio"]:checked:after {
  background-color: #ffa500;
}
.thankyoupagetextalignment {
  padding-left: 5px;
}
.step-container {
  display: flex;
  -webkit-box-pack: center;
  position: relative;
  margin-right: -10%;
  width: 100%;
  text-align: center;
}
.step-container .step-option:first-child {
  -webkit-box-flex: 0;
  flex: 0 0 10%;
}
.step-container .step-option:last-child {
  -webkit-box-flex: 0;
  flex: 0 0 10%;
}
.step-container .step-option:first-child {
  text-align: left;
}
.step-container .step-option {
  position: relative;
  -webkit-box-flex: 0;
  flex: 0 0 20%;
  text-align: center;
  padding-left: 0;
}
.step-container .step-option .step-label {
  position: relative;
  text-align: center;
}
.step-container .step-option::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #fff;
}

.step-container .step-option .step-txt {
  display: inline-block;
  font-size: 12px;
  padding-top: 20px;
  cursor: pointer;
  color: #fff;
  white-space: nowrap;
  text-align: center;
}
.step-container .step-option:first-child .step-txt {
  text-align: left;
}
.step-container .step-option:last-child .step-txt {
  text-align: right;
  margin-right: -15px;
}
.marginleftn8 {
  margin-left: -4px;
}
.width140 {
  width: 140%;
}
.step-container .step-option .step-label .step-txt::after {
  content: "";
  width: 7px;
  height: 7px;
  background: #e1dbd0;
  position: absolute;
  left: 50%;
  top: -3px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 10;
  text-align: center;
}
.step-container .step-option:first-child .step-label .step-txt::after {
  left: 0;
}
.step-container .step-option:last-child .step-label .step-txt::after {
  left: inherit;
  right: -20px;
}
.step-container .step-option .step-label:hover .step-txt {
  color: #b99855;
}
.step-container .step-option .step-label:hover .step-txt::after,
.step-container .step-option .step-label:hover .step-txt::after,
.step-container .step-option input:checked ~ .step-label .step-txt::after {
  width: 12px;
  height: 12px;
  background: #b99855;
  top: -6px;
}
.step-container .step-option input {
  display: none;
  text-align: center;
}

.rtl .step-container {
  text-align: right;
  padding-right: 95px;
}

.rtl .modalcontent {
  text-align: right !important;
}

.rtl .textest {
  margin-left: 0 !important;
}

.rtl .Para {
  text-align: right !important;
}
.rtl .step-container .step-option:last-child .step-txt {
  text-align: right;
  margin-right: 0px;
}
.rtl .step-container .step-option:first-child .step-label .step-txt::after {
  left: 33px;
}
.rtl .step-container .step-option:last-child .step-label .step-txt::after {
  left: inherit;
  right: 45px;
}

.rtl .step-container .step-option:first-child {
  -webkit-box-flex: 0;
  flex: 0 0 10%;
  text-align: right;
}
.rtl .step-container .step-option:last-child {
  -webkit-box-flex: 0;
  flex: 0 0 10%;
  text-align: left;
}
.back-btn:disabled,
.back-btn:focus,
.back-btn:hover {
  color: #fff;
}

.back-btn {
  color: #b99855;
}

.back-btn:hover {
  text-decoration: none;
}

.marginleftn45 {
  margin-left: -50px;
}
.rtl .marginleftn45 {
  margin-left: -25px !important;
}
.checkboxContainer {
  display: flex;
  width: 100%;
  gap: 50px;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  border-bottom: 1px solid rgb(126, 124, 124);
}
.checkboxContainer2 {
  display: flex;
  width: 100%;
  gap: 27px;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  border-bottom: 1px solid rgb(126, 124, 124);
}
@media (max-width: 1024px) {
  .container {
    padding: 0;
  }
  .tabs-container {
    padding: 0 10px;
    background-color: transparent;
    position: relative;
  }
  .step-container .step-option:last-child .step-label .step-txt::after {
    right: -15px;
  }
  .rtl .step-container {
    text-align: right;
    padding-right: 81px;
  }
}
@media (max-width: 768px) {
  .q-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .ans-btn {
    text-align: left;
  }
  .tab-item {
    font-size: 10px;
    padding: 1rem 1.5rem;
  }
  .step-container {
    justify-content: center;
    font-size: 10px;
  }
  .step-container .step-option:last-child .step-label .step-txt::after {
    right: -11px;
  }
  .rtl .step-container {
    text-align: right;
    padding-right: 45px;
  }
  .rtl .step-container .step-option:last-child .step-txt {
    /* margin-right: 18px !important; */
  }
}

@media (max-width: 576px) {
  .q-container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .tab-item {
    padding: 1rem 0.1rem;
  }

  .step-container .step-option .step-txt {
    white-space: normal;
  }

  .marginleftn8 {
    margin-left: -15px;
  }
  .step-container .step-option:last-child {
    flex: 0 0 10%;
  }

  .step-container .step-option:first-child,
  .step-container .step-option {
    flex: 0 0 20%;
  }
  .step-container .step-option:last-child .step-label .step-txt::after {
    right: -10px;
  }
}
@media (max-width: 425px) {
  .rtl .step-container {
    padding-right: 45px;
  }
}

@media (width: 360px) {
  .checkboxContainer ul {
    /* flex-direction: column; */
    padding-left: 0;
  }
  .checkboxContainer2 ul {
    /* flex-direction: column; */
    padding-left: 0px;
  }
  .checkboxContainer li {
    /* margin-top: 10px; */
  }
  .checkboxContainer2 li {
    /* margin-top: 10px; */
    /* margin-left: 10px; */
  }
  .checkboxContainer {
    gap: 30px !important;
  }
  /* .checkboxContainer2{
        gap:20px !important;
    } */
  .checkboxContainer2 {
    gap: 25px !important;
  }
  .marginleftn45 {
    margin-left: 0px;
  }
}

@media (width: 768px) {
  .checkboxContainer ul {
    /* flex-direction: column; */
    padding-left: 0;
  }
  .checkboxContainer2 ul {
    /* flex-direction: column; */
    padding-left: 0px;
  }
  .checkboxContainer li {
    /* margin-top: 10px; */
  }
  .checkboxContainer2 li {
    /* margin-top: 10px; */
    /* margin-left: 10px; */
  }
  .checkboxContainer {
    gap: 20px;
  }
  /* .checkboxContainer2{
        gap:20px !important;
    } */
  .marginleftn45 {
    margin-left: -15px;
  }
  /* .rtl.step-container .step-option:last-child .step-txt {
    margin-right: -6px !important;
} */
}

@media (width: 320px) {
  .alignment {
    margin-left: 0px;
  }
  .checkboxContainer {
    gap: 9px;
  }
  .checkboxContainer2 {
    gap: 3px !important;
  }
  .marginleftn45 {
    margin-left: -15px;
  }
}

@media (max-width: 425px) {
  .checkboxContainer ul {
    flex-direction: column;
    padding-left: 0;
  }
  .checkboxContainer2 ul {
    flex-direction: column;
    padding-left: 0;
  }
  .checkboxContainer li {
    margin-top: 10px;
  }
  .checkboxContainer2 li {
    margin-top: 10px;
  }
  .checkboxContainer2 {
    gap: 45px;
  }
  .checkboxContainer1 {
    gap: 40px;
  }
  .marginleftn45 {
    margin-left: -15px;
  }
}

@media (width: 360px) {
  .rtl .step-container .step-option:last-child .step-txt {
    margin-right: 18px !important;
  }
}
@media (width: 375px) {
  .rtl .step-container .step-option:last-child .step-txt {
    margin-right: 18px !important;
  }
}
@media (width: 414px) {
  .rtl .step-container .step-option:last-child .step-txt {
    margin-right: 18px !important;
  }
}

@media only screen and (max-width: 375px) {
  .rtl .step-container .step-option:last-child {
    flex: 0 0 15%;
    /* text-align: left; */
    padding-left: 17px;
  }
}
@media only screen and (max-width: 280px) {
  .rtl .step-container .step-option:last-child {
    padding-left: 0px;
  }
}
@media only screen and (max-width: 360px) {
  .rtl .step-container .step-option:last-child {
    padding-left: 0px;
  }
}
@media only screen and (max-width: 320px) {
  .rtl .step-container .step-option:last-child {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 360px) {
  .rtl .step-container .step-option:first-child {
    padding-right: 13px;
  }
}
@media (max-width: 568px) {
  /* .marginleftn8 {
  margin-left: 15px !important;
} */
  .rtl .step-container .step-option:last-child .step-txt {
    text-align: right;
    margin-right: 25px;
  }
}
