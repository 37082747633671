@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/Gotham/English/Gotham-Light.ttf');
}
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Gotham/English/Gotham-Bold.ttf');
}
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/Gotham/English/Gotham-Book.ttf');
}


body {
  margin: 0;
  font-family: 'Gotham',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: 'Gotham', 'Courier New',
    monospace;
}
