.alert-overlay {
    text-align: center;
    position: fixed;
    top: 10%;
    left: 40%;
    z-index: 5;
}

.alert-box {
    color: azure;
    background: #b99855 ;
    border-color: #b99855;
    box-shadow: #b99855;
}