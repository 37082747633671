.agreeAndContinue {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 20px;
    color: #b99855;
    border: 1px solid #b99855;
    background-color: #1a1a1a;
    font-weight: 500;
    font-size: 14px;
    padding: 0.375rem 1.7rem;
    border-radius: 2px;
}
.agreeAndContinue:hover {
    background-color: #b99855;
    color: #ffff;
}
.modalcontent {
    font-size: 14px;
    font-weight: 500;
}
.main-header {
    padding-left: 4.25rem;
    padding-right: 4.25rem;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    /* cursor: pointer; */
}
.icon {
    width: 24px !important;
    height: 24px !important;
}
.header-row {
    align-items: center;
}
.header-col {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    padding: 0 0 0 10px;
}
.rtl .header-col {
    text-align: right;
    padding: 0 10px 0 0;
}
.logo {
    flex-grow: unset;
    padding: 0;
}
.subheader {
    color: #b99855;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 10px;
}
.miniheader {
    color: #828282;
    margin-top: 0;
    font-size: 13px;
}

.profile-col {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: -40px;
    text-align: right;
}
.rtl .profile-col {
    text-align: left;
}
.profile-col .miniheader {
    color: #b99855;
}
.profile-coln {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    margin-top: -60px;
    text-align: right;
}
.rtl .profile-coln {
    text-align: left;
}
.profile-coln .miniheader {
    color: #b99855;
}
.lang span {
    cursor: pointer;
}
@media (max-width: 768px) {
    .main-header {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    /* .subheader ,.miniheader {
        text-align: right;
    } */
    .header-row {
        padding: 10px;
        
    }
    .subheader{
        font-size:1rem;
    }
    .miniheader{
        margin-top: -7px;

    }
    /* .lang {
    text-align:left
} */
/* .miniheaderpaddingpri{
    margin-right: -7px;
    margin-top: -10px;
}
.miniheaderpaddingrel{
    margin-right: -20px;
} */

}

@media (max-width: 576px) {
    .main-header {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    .profile-col{
        margin-top: -10px;
    }
   
    .lang {
    text-align:left
    } 
    .miniheaderpaddingpri{
    margin-right: -7px;
    margin-top: -10px;
    }
    .miniheaderpaddingrel{
    margin-right: -20px;
    margin-top: 0px;

    }
     .subheader  {
        text-align: right;
    } 
}

.rtl .modalcontent {
    text-align: right !important;
}
