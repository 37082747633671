section.app-loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b99855;
}
section.app-loader {
  top: 0;
  position: absolute;
  z-index: 7;
  bottom: 0;
  left: 0;
  right:0;
}
.overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: #111111b8;
}
