main.login {
  /* height: 100vh;
  width: 100vw; */
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.login-nav {
  padding: 25px 80px 0 80px;
}
main.login .login-header {
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  color: #b99855;
  margin: 24px 0px 0;
}

.login-form {
  margin-top: 30px;
}

.login-form .form-group {
  /* margin-top: 16px; */
  width: 100%;
  color: #fff;
}

.login-actions {
  margin-top: 20px;
  text-align: center;
}

.login-actions .form-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form .form-control,
.login-form .form-control:focus-within {
  width: 380px;
  border: 0 none;
  border-bottom: solid 1px #828282;
  background-color: transparent !important;
  border-radius: 0;
  color:#ffffff;
  font-style: italic;
}
.login-form .form-control::placeholder {
  font-style: italic;
  color:#828282;
}

.fpass-link,.fpass-link:hover {
  color: #b99855;
  font-size: 14px;
  line-height: 120%;
}
/* .login-actions .form-group button {
    width: 75%;
    border: 2px solid rgb(185, 152, 85);
    box-sizing: border-box;
    color: rgb(185, 152, 85);
    margin-top: 20px;
} */

.login-actions .form-group button {
  width: 244px;
  height: 38px;
  background: rgba(34, 34, 34, 0.8);
  border: 2px solid #b99855;
  box-sizing: border-box;
  margin: 24px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #b99855;
  text-align: center;
  border-radius: 0;
}

.login-nav .icon-inner svg {
  display: block;
  height: 100%;
  width: 100%;
}


.logo {
  text-align: center;
}


.errorContainer {
  margin:10px 0;
  width: 380px;
}

.errorContainer .alert {
  color:#eb445a;
  border: solid 1px #eb445a;
}