.main-app .header {
  background: #222222;
  /* position: fixed; */
  width: 100%;
}

.main-app {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  background: #151515;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  color: #fff;
  z-index: 0;
}
.main-app.rtl .container {
  direction: rtl;
}
.main-app.rtl p, .body-rtl {
  text-align: right;
}
/* .wrapper {
  background-color: #1a1a1a;
} */
.main-app::before {
  content: " ";
  background: url(../../icons/Background_Leaf_Image.svg) no-repeat 215px 0px;
  position: absolute;
  height: 100%;
  width: 100%;
  margin-top: 63px;
  right: 0px;
  z-index: -2;
}

.main-app::after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 236px;
  background: linear-gradient(180deg, #131313 0%, rgba(17, 17, 17, 0) 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
  z-index: -1;
}
/* .mw-7 {
  max-width: calc(100vw - 250px);
} */
.mw-8 {
  max-width: 80%;
}
.active section.menu-title {
  color: #fff;
  border-bottom: 2px solid;
}
.active section.menu-title svg path {
  fill: #fff !important;
}
section.menu-title {
  font-size: 12px;
  text-align: end;
  color: rgb(130, 130, 130);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 45px;
  padding: 19px 0;
}
span.icon {
  margin-right: 11px;
}
section.menu-title-last {
  margin-right: 0;
}
section.menu-title-last span.icon {
  margin-left: 10px;
}
.main-container {
  height: 100vh;
  overflow: auto;
}
.navbar-nav > a:hover {
  text-decoration: none;
}
.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

body ::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}
body ::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4) !important;
  border-radius: 10px !important;
}
body ::-webkit-scrollbar-thumb {
  background-color: #828282 !important;
  border-radius: 10px !important;
}

.header-wrapper {
  /* margin-top: 71px; */
  margin-bottom: 50px;
}

.header-wrapper .headerText {
  margin-top: 0;
}

.link-wrapper {
  text-align: right;
  width: 100%;
  height: 100%;
}

.link-wrapper span {
  margin-left: 20px;
}

/* Customize the label (the container) */
.radio-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-wrapper input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radio-btn {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: solid 2px rgb(165, 210, 220);
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
/* .radio-wrapper:hover input ~ .radio-btn {
  background-color: #ccc;
} */

/* When the radio button is checked, add a blue background */
/* .radio-wrapper input:checked ~ .radio-btn {
  background-color: #2196F3;
} */

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-btn:after {
  content: "";
  position: absolute;
  display: none;

}

/* Show the indicator (dot/circle) when checked */
.radio-wrapper input:checked ~ .radio-btn:after {
  display: block;
}



/* Style the indicator (dot/circle) */
.radio-wrapper .radio-btn:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(165, 210, 220);
}

/* Customize the label (the container) */
.checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-wrapper input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-btn {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color:transparent;
  border: solid 1px rgba(253, 253, 253, 0.51);
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
/* .checkbox-wrapper:hover input ~ .checkbox-btn {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
.checkbox-wrapper input:checked ~ .checkbox-btn {
  background-color: rgb(165, 210, 220);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-btn:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-wrapper input:checked ~ .checkbox-btn:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-wrapper .checkbox-btn:after {
  left: 5px;
  top: 1px;
  width: 5px;
  height: 13px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.radio-wrapper.other-wrapper input ~ .other-text {
  display: none;
  max-width: 300px;
}
.radio-wrapper.other-wrapper input:checked ~ .other-text {
  display: inline-block;
}

.rtl .checkbox-wrapper, .rtl .radio-wrapper{
  text-align: right;
  padding-left: 0;
  padding-right: 35px;
}
.rtl .radio-btn, .rtl .checkbox-btn {
  left: auto;
  right:0;
}

.rtl .radio-wrapper.other-wrapper input ~ .other-text {
  margin-right: 10px;
}

@media only screen and (max-width: 760px) {
  .r-sm-mt {
    margin-top: 20px;
  }
}

@media (min-width:641px)  { 
  .float-left-btn {
    float: left;
   }
   .float-right-btn {
     float: right;
   }

   .rtl .float-left-btn {
    float: right;
   }
   .rtl .float-right-btn {
     float: left;
   }

   .nextButton {
    margin: 0 auto;
    margin-bottom: 20px;
    color: #b99855;
    border: 1px solid #b99855;
    background-color: #1a1a1a;
    font-weight: 500;
    font-size: 14px;
    padding: .375rem 1.7rem;
    width: fit-content;
  }
  .nextButton:hover{
     background-color: #b99855;
     color: #ffff
  }
}

@media (max-width:641px)  { 
  .nextButton {
    width: 102%;
    margin: 0 auto;
    margin-bottom: 20px;
    color: #b99855;
    border: 1px solid #b99855;
    background-color: #1a1a1a;
    font-weight: 500;
    font-size: 14px;
    padding: .375rem 1.7rem;
  }
  .nextButton:hover{
     background-color: #b99855;
     color: #ffff
  }
}
.validation-error {
  width: 100%;
  margin-top: 20px;
  font-size: 15px;
  color: #dc3545;
}
.validation-errorIn {
  width: 100%;
  margin-top: 10px;
  font-size: 15px;
  color: #dc3545;
}
.margintopn25{
  margin-top: -25px;
}
.validation-errorq5 {
  width: 100%;
  font-size: 15px;
  color: #dc3545;
}

.validation-error-amount {
  width: 100%;
  margin-top: 20px;
  font-size: 12px;
  color: #dc3545;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.body-rtl .confirmation-modal .modal-content { direction: rtl}




.body-rtl .tick img{
  left: 35px;
    height: 10px ;
    position: relative ;
    top: -27px ;
}


.body-rtl .question2grid {
    display: flex;
    grid-gap: 20px;
    gap: 14px;
    /* margin-left: 60px; */
    margin-right: 35px;
    flex-wrap: wrap;
}

.body-rtl .assetAllocation {
    margin-right: 35px;
    color: #b99855;
    line-height: 22px;
    font-weight: 400;
    margin-top: 20px;
}

.body-rtl .question6grid {
    margin: 10px 0;
    grid-gap: 25px;
    gap: 25px;
    /* margin-left: 43px; */
    margin-right: 20px;
}

.body-rtl .capitalgrid {
    display: grid;
    /* margin-left: 55px; */
    margin-right: 30px;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 17px;
    column-gap: 17px;
    text-align: center;
    padding: 10px;
    background-color: #282828;
    align-items: center;
}


.body-rtl .float-left-btn {float :right;}
.body-rtl .float-right-btn { float:left;}

.body-rtl .left-arrow {
  display: inline;
}


.body-rtl .right-arrow {
  display: none;
}

@media (max-width: 414px) {
  .body-rtl .capitalgrid {
    display: grid;
    /* margin-left: 55px; */
     margin-right: 25px;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 17px;
    grid-column-gap: 17px;
    column-gap: 8px;
    text-align: center;
    padding: 10px;
    background-color: #282828;
    align-items: center;
}
}

@media (max-width: 768px) {
  .body-rtl .question2grid {
  display: flex;
    grid-gap: 10px !important;
    gap: 10px !important;
    /* margin-left: 60px; */
    margin-right: 29px !important;
    flex-wrap: wrap;
}
.assetAllocation {
  margin-left: 34px;
}

.question2grid {
  margin-left: 35px;
  grid-gap: 10px;
  gap: 10px;
}
.body-rtl .assetAllocation {
    margin-right: 38px !important;
    color: #b99855;
    line-height: 22px;
    font-weight: 400;
    margin-top: 20px;
}
.body-rtl .capitalgrid {
    display: grid;
    /* margin-left: 55px; */
    margin-right: 29px !important;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 17px;
    grid-column-gap: 17px;
    column-gap: 17px;
    text-align: center;
    padding: 10px;
    background-color: #282828;
    align-items: center;
    width:320px !important;
}
.body-rtl .tick img {
    left: 38px;
    height: 15px;
    position: relative;
    top: -25px;
}
.body-rtl .question6grid {
    margin: 12px 0;
    grid-gap: 25px;
    grid-gap: 25px;
    gap: 33px;
    /* margin-left: 43px; */
    margin-right: 20px !important;
}

.question6grid {
  margin-left: 21px;
}


}


.body-rtl .numberalignment {
    direction: ltr;
    unicode-bidi: bidi-override;
}
.body-rtl .textalignment {
   direction: ltr;
    unicode-bidi: bidi-override;
    text-align: initial;
}
@media (max-width: 1024px){
  .body-rtl .question2grid {
    /* margin-left: 60px; */
    margin-right: 60px;
    
}
.body-rtl .question6grid {
    margin-right: 43px;
}
.body-rtl .assetAllocation {
    margin-right: 60px;
    
}
.body-rtl .capitalgrid {
    display: grid;
    /* margin-left: 55px; */
    margin-right: 55px;
   
}

}
@media(max-width:540px){
  
  .body-rtl .capitalgrid{
    margin-right: 36px !important;
    width: 420px !important;

  }
  .body-rtl .question2grid {
    margin-right: 40px !important;
    margin-left: -32px !important;

  }
  .body-rtl .assetAllocation{
    margin-right: 40px !important;
  }
  .body-rtl .question6grid{
    margin-right: 26px !important;

  }
}

@media(max-width:412px){
  .body-rtl .capitalgrid {
  margin-right: 25px !important;
  width: 320px !important;
  }
  .capitalgrid{
    margin-left: 9px !important;
    width: 320px !important;
  }
  .question2grid{
  margin-left: 10px;
    grid-gap: 5px;
    gap: 5px;
  }
  .assetAllocation {
    margin-left: 28px;
}
  .body-rtl .question2grid{
    margin-right: 25px !important;

  }
  .body-rtl .assetAllocation{
    margin-right: 30px !important;

  }
  .body-rtl .question6grid{
    margin-right: 13px !important;
  }
}

@media (max-width: 360px){
.capitalgrid {
    margin-left: -17px !important;
    width: 310px !important;
}
.body-rtl .question2grid {
  margin-right: -20px !important;

}
.body-rtl .capitalgrid {
  margin-right: -5px !important;
  width: 300px !important;
}
.question2grid{
  grid-gap: 10px;
    gap: 10px;
}
}
@media (max-width: 320px){
.body-rtl .capitalgrid {
    /* margin-right: -12px !important; */
    width: 266px !important;
    row-gap: 5px !important;
    column-gap: 5px !important;

}
.capitalgrid {
    width: 269px !important;
    grid-row-gap: 0px !important;
    row-gap: 5px !important;
    grid-column-gap: 5px !important;
    column-gap: 5px !important;
    margin-left: -16px !important;

}
.status {
  font-size: 14px !important;
  height: 90px !important;
}
.nowContainer{
margin-right: -25px;
}
.value {
  margin-top: 4px !important;
  font-size: 12px !important;
}

}
/* @media (max-width: 376px){
  .capitalgrid {
    margin-left: -12px !important;
}
.body-rtl .capitalgrid {
  margin-right: -15px !important;
  
}
} */
@media (max-width: 415px){
  .capitalgrid{
    width: 325px !important;
    margin-left: 15px !important;
  }
  .status{
    font-size: 18px !important;
  }
  .value {
    margin-top: 8px !important;
    font-size: 16px !important;
}
.body-rtl .capitalgrid {
  width: 312px !important;
  margin-right: 20px !important;
}
.body-rtl .question6grid{
  margin-right: 15px !important;
}
.body-rtl .assetAllocation {
  margin-right: 30px !important;
}
.body-rtl .question2grid {
  margin-right: 30px !important;
  margin-left: -32px !important;
  grid-gap: 5px !important;
  gap: 5px !important;
}
}
@media (max-width: 376px){
.body-rtl .capitalgrid {
  width: 312px !important;
  margin-right: -3px !important;
}
.capitalgrid {
  width: 320px !important;
  margin-left: -17px !important;
}
.body-rtl .question2grid {
  grid-gap: 4px !important;
  gap: 4px !important;
  margin-right: -20px !important;
  margin-left: -78px !important;

}
.body-rtl .assetAllocation {
  margin-right: 25px !important;

}
.body-rtl .question6grid {
  margin-right: 14px !important;

}
.question2grid{
  margin-left: 30px;

}
}
@media (max-width:1440px){
  .body-rtl .question2grid {
    margin-right: 61px; 
  }
  .body-rtl .assetAllocation {
    margin-right: 65px;  
  }
  .body-rtl .question6grid {
    margin-right: 48px;
  }
  .body-rtl .capitalgrid {
    margin-right: 53px;
  }
}

@media  (max-width:425){
  .body-rtl .question2grid {
    grid-gap: 8px !important;
    gap: 8px !important;
    margin-left: -10px !important;

  }

}