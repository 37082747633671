.suitablity-container {
  width: 92%;
  margin: 0 auto;
}
.question7input span {
  width: fit-content;
}
.question7input div {
  display: flex;
}
.button-flex {
  width: 400px;
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}
.q7-value:focus {
  border: 0px;
  color: rgb(245, 245, 245);
}

.buttonClass {
  background-color: #222222;
  border: none;
  outline: none;
  width: 100%;
}
.buttonClass:active,
.buttonClass:hover {
  background-color: #b99855;
  border: none;
  outline: none;
}
.enter-amount {
  width: 50px;
  padding: 5px;
  margin: 0 5px;
  background: #222222;
  outline: none;
  border: none;
  color: white;
}

.question {
  margin: 30px 0;
}
.small-font {
  font-size: 14px;
}
.sutability-textArea {
  width: 80%;
  background: #222222;
  height: 120px;
  outline: none;
  border: none;
  color: rgb(85, 84, 84);
  padding: 5px;
}
.buttonBox {
  display: flex;
  gap: 30px;
  width: 80%;
  flex-wrap: wrap;
}
.question25Button {
  width: fit-content;
}
.sliderItem {
  display: grid;
  align-items: center;
  /* grid-template-columns: 1fr 6fr 1fr; */
  margin: 30px 0;
}
.sliderItem > .ans-row {
  align-items: center;
}
.sliderItem > .ans-row .ans-col:first-child {
  line-height: 2em;
  font-size: 13px;
}
.margintopn25 {
  margin-top: -25px;
}
.margintopn15 {
  margin-top: -15px;
}
.margintopn30 {
  margin-top: 26px;
}
.margintopn35 {
  margin-top: -35px;
}
.margintop30 {
  margin-top: 30px;
}
.margintop15 {
  margin-top: 15px;
}
.margintopn8 {
  margin-top: -8px !important;
}
.margintopn15 {
  margin-top: -15px !important;
}
/* .textcolor{
  color:#b99855
} */
.slider {
  width: 100%;
  margin: 0 auto;
}
.smallcontent {
  color: #c7c7c7;
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
}
.q7-value {
  margin-left: 15px;
}
.headercontent {
  margin-bottom: 0.25rem !important;
}
.customtextwidth {
  width: 30%;
  position: relative;
  bottom: -35px;
  right: 40px;
}
.customtextwidthotr {
  width: 45%;
  position: relative;
  bottom: -4px;
}
.rtl .ans-col {
  text-align: right;
}
.marginleftp50 {
  margin-left: 100px;
}

.align-right {
  text-align: right;
}

.rc-slider-handle {
  width: 20px;
  height: 20px;
  margin-top: -7px;
}

/* .q-container {
  padding: 40px 4.25rem;
}
.question-wrapper {
  margin-bottom: 3rem;
}
.question-wrapper .q-text {
  margin-bottom: 25px;
}

.question-wrapper .ans-row .ans-col{
  padding-top:5px;
  padding-bottom:5px;
} */

@media (max-width: 1024px) {
  .container {
    padding: 0;
  }
  .tabs-container {
    padding: 0 10px;
  }
}
@media (max-width: 768px) {
  .q-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .margintop10 {
    margin-top: 10px;
  }
  .ans-btn {
    text-align: left;
  }
  .tab-item {
    font-size: 10px;
    padding: 1rem 1.5rem;
  }
  .question7input {
    margin: 0;
  }
  .question7input span {
    width: fit-content;
  }
  .question7input div {
    display: flex;
  }
}

@media (max-width: 576px) {
  .question7input input {
    margin: 0;
  }
  .q-container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .tab-item {
    padding: 1rem 0.1rem;
    text-align: center;
  }
  .q7-value {
    border: 0px;
    /* margin-left: 84px; */
    color: rgb(245, 245, 245);
  }
  .question7input span {
    width: fit-content;
  }
}

@media (min-device-width: 320px) {
  .q7-value {
    border: 0px;
    margin-left: 0px;
    color: rgb(245, 245, 245);
  }
}
@media (min-device-width: 375px) {
  .q7-value {
    border: 0px;
    /* margin-left: 84px; */
    color: rgb(245, 245, 245);
  }
}
@media (min-device-width: 425px) {
  .q7-value {
    border: 0px;
    margin-left: 15px;
    color: rgb(245, 245, 245);
  }
}

@media (min-device-width: 790px) {
  .q7-value {
    border: 0px;
    margin-left: 15px;
    color: rgb(245, 245, 245);
  }
}
.mar-button {
  margin-bottom: 10px;
}

#disableQuestion {
  height: 100%;
  position: relative;
  filter: brightness(70%);
  opacity: 0.7;
}
#disableQuestion {
  height: 100%;
  position: relative;
  filter: brightness(70%);
  opacity: 0.7;
}
#disableQuestion:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}
.close-cross {
  position: absolute;
  right: 25px;
  top: 25px;
}
.clienttopheader {
  margin-top: 25px;
  margin-bottom: 35px;
}

@media (max-width: 768px) {
  .margintop10 {
    margin-top: 10px;
  }
  .marginbottom10 {
    margin-bottom: 10px;
  }
  .question7input span {
    width: fit-content;
  }
}

@media (width: 768px) {
  .margintopn25 {
    margin-top: 0px !important;
  }
}

@media (max-width: 768px) {
  .margintopn25 {
    margin-top: 0px !important;
  }
}

.investment-input .form-control:disabled,
.form-control[readonly] {
  background-color: #222 !important;
  opacity: 1;
}
