
@media (max-width: 571px) {  

    .marginleftn8 {
        margin-left: -4px;
    }
}
@media (min-width: 641px){
    .goToInvestment{
        width: 56%;
    }
}
