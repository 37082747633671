.summery-heading {
    font-weight: 600;
    text-align: center;
    color: #b99855;
}
.nowContainer h5 {
    font-weight: 600;
}
.point {
    display: flex;
    gap: 30px;
    align-items: flex-start;
    /* margin: 15px 0; */
}
.point p,
.nowContainer p {
    color: #b99855;
    line-height: 22px;
    font-weight: 400;
}

.point label {
    display: inline-block;
    width: 25px;
    height: 27px;
    background-color: #2e2e2e;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    line-height: 28px;
}
.summery-point {
    color: #b99855;
}
.summerySpanHighlight {
    color: #ffff;
    font-weight: 600;
}
.summerySpanHighlight1 {
    color: #ffff;
    font-weight: 600;
}
.modalSpanHighlight{
color: #b99855;
padding-left: 5px;
}
.padding_lasttext{
    padding-left: 5px;
}
.nowContainer {
    border-radius: 5px;
    border: 3px solid #b99855;
    width: fit-content;
    text-align: center;
    /* width: max-content; */
    height: 105px;
}

.nowHeader {
    background-color: #b99855;
    padding: 2px;
    font-weight: 600;
    /* border-radius: 8px; */
    width: 177px;
}
.withinyear {
    padding: 2px;
    border-radius: 5px;
    width: 177px;
}
.withinyear p {
    font-weight: 300;
    font-size: 20px;
    color: white;
}

.nowContainer p {
    padding: 0;
    margin: 8px 0;
    font-size: 14px;
    font-weight: 700;
}
.assetAllocation {
    margin-left: 55px;
    color: #b99855;
    line-height: 22px;
    font-weight: 400;
    margin-top: 20px;
}
.capitalgrid {
    display: grid;
    margin-left: 55px;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 10px;
    column-gap: 17px;
    text-align: center;
    padding: 10px;
    background-color: #282828;
    align-items: center;
}
.capital {
    color: #b99855;
}
.status {
    background-color: #b99855;
    font-weight: 600;
    font-size: 14px;
    padding: 18px 11px;
    border-radius: 5px;
    width: 100%;
    position: relative;
    height: 105px;
}
.tick {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 36px solid transparent;
    /* border-bottom: 20px solid transparent; */
    right: 0;
    bottom: 0;

    border-right: 38px solid white;
}
.tick img {
    left: 19px;
    height: 15px;
    position: relative;
    top: -20px;
}
.rtl .tick img {
    left: 35px !important;
    height: 15px !important;
    position: relative !important;
    top: -22px !important;
}
.cross {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 36px solid transparent;
    /* border-bottom: 20px solid transparent; */
    right: 0;
    bottom: 0;

    border-right: 38px solid black;
}
.cross img {
    left: 19px;
    height: 15px;
    position: relative;
    top: -22px;
}
#inactiveStatus {
    background-color: black;
    color: #b99855;
    border: 1px solid #b99855;
    font-size: 14px;
}
.riskScore {
    text-align: center;
    color: #b99855;
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: 500;
}
.scoreGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 55px;
    gap: 30px;
}
.score-container {
    border: 1px solid #b99855;
    padding: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 24px;
    color: #b99855;
}
.leftpadding {
    padding: 2px;
}
.score-container p {
    font-size: 55px;
}
.score-container span {
    font-weight: 900;
    font-size: 24px;
}
.score-buttonContainer {
    margin: 0 auto;
    width: fit-content;
    margin-top: 40px;
    display: flex;
    gap: 50px;
}
.sumbitButton {
    margin: 0 auto;
    color: #b99855;
    border: 1px solid #b99855;
    background-color: #1a1a1a;
    font-weight: 600;
    padding: 5px 37px;
}
.sumbitButton:hover,
.sumbitButton:focus,
.agreeAndContinue:active {
    background-color: #b99855;
    color: #ffff;
    outline: none;
    border: none;
}

.progress1 {
    height: 10px;
    margin: 2px;
    background: #ccc;
    flex: 1;
    display: flex;
}
.progress1-bar {
    height: 10px;
    background: #a38851; /* Chrome10-25,Safari5.1-6 */
}
.progress_bar_end {
    border-radius: 0px 10px 10px 0px;
}
.progress_bar_start {
    border-radius: 10px 0px 0px 10px;
}
.labelp {
    margin-top: -3vh;
    font-size: 12px;
    width: fit-content;
}
/* .rtl .numberalignment{
direction: ltr;
} */
.pointr {
    background: #000;
    border: 2px solid #a38851;
    border-radius: 15px;
    height: 20px;
    width: 20px;
    margin-top: -5px;
}
.cnt {
    margin-top: 10px;
    display: flex;
    width: 100%;
}
.rtl .labelcontent {
    text-align: right;
    display: block;
    float: right;
}
.rtl .point .summery-point {
    text-align: right;
    float: right;
    display: block;
}
.rtl .nowContainer {
    text-align: right;
}

.float-right-label {
    float: right;
}
.secondtext {
    color: white;
    font-size: 12px;
}
.value {
    margin-top: 11px;
    display: block;
    font-size: 15px;
    line-height: 1;
}
.question2grid {
    display: flex;
    gap: 20px;
    margin-left: 60px;
    flex-wrap: wrap;
}
.question6grid {
    margin: 10px 0;
    gap: 25px;
    margin-left: 43px;
}
.confirm-box {
    position: fixed;
    width: 400px;
    height: 25vh;
    top: 30%;
    left: calc(50% - 200px);
    margin: 0 auto;
    background-color: #2e2e2e;
    padding: 50px;
    z-index: 1051;
}
.header-pop-up{
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    margin-top: -30px;
}
.firstline-pop-up{
    font-size: 15px;
    text-align: center;
    margin-top: -10px;
}
@media (max-width: 571px) {
    .question6grid {
        margin: 10px 0;
        gap: 25px;
        margin-left: 10px;
    }
    .value {
        margin-top: 11px;
        display: block;
        font-size: 18px;
        line-height: 1;
    }
    .rowsummary {
        flex-wrap: nowrap;
    }
    .confirmation-modal .modal-body {
        padding: 25px;
    }
    .summery-heading {
        font-size: 1.8rem;
    }
    .summery-point {
        font-size: 16px;
    }
    .riskScore {
        font-size: 1.8rem;
    }
    .summerySpanHighlight1 {
        /* padding-left: 74px; */
    }
    .nowHeader {
        min-width: 140px;
    }
    .question2grid {
        margin-left: 20px;
        gap: 15px;
    }
}
@media (max-width: 768px) {
    .question6grid {
        margin: 10px 0;
        gap: 25px;
        margin-left: 14px;
    }
    .secondtext {
        white-space: nowrap;
    }
    .tick img {
        left: 19px;
        top: -25px;
        height: 15px;
        position: relative;
    }
    .nowHeader {
        min-width: 150px;
        max-width: max-content;
        height: 30px;
        font-size: larger;
    }
    .question2grid {
        margin-left: 30px;
        gap: 5px;
    }
    .margintop10 {
        margin-top: 10px;
    }
    .assetAllocation {
        margin-left: 35px;
    }
    .summery-point {
        font-size: 16px;
    }
    .point label {
        padding: 8px;
        line-height: 10px;
    }
    .summery-heading {
        font-size: 1.8rem;
    }
    .riskScore {
        font-size: 1.8rem;
    }
    .withinyear {
        width: 110px;
    }
    .capitalgrid {
        display: grid;
        margin-left: 30px;
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 0px;
        row-gap: 12px;
        grid-column-gap: 0px;
        column-gap: 10px;
        text-align: center;
        padding: 2px;
        background-color: #282828;
        align-items: center;
    }
    .status {
        background-color: #b99855;
        font-weight: 600;
        font-size: 20px;
        padding: 5px 6px;
        border-radius: 5px;
        width: 100%;
        position: relative;
        height: 105px !important;
    }
}


@media (width: 360px) {
    .question6grid {
        margin: 29px ;
        gap: 25px;
        margin-left: 14px;
    }
}
/* @media(max-width: 320px){
    .value {
        font-size: 12px !important;  
    } 
    .status {
        font-size: 16px;
        height: 90px !important;
    }
    
} */