.portfolio-container {
    width: 92%;
    margin: 0 auto;
}

.container-heading,
.checkbox-container {
    margin-top: 30px;
}
.checkbox-item {
    display: flex;
}
.checkbox-label {
    margin-left: 30px;
}
.alignment{
     margin-left: 30px;
}
.buttonContainer {
    align-items: center;
    margin-top: 60px;
    margin-bottom: 60px;
    padding-bottom: 20px;
}
.back-btn {
    background: none;
    border: none;
    color: #b99855;
}
.goToInvestment {
    width: 30%;
    margin-left: 50px;
    margin-bottom: 0;
}
.pleaseApply {
    font-size: 14px;
    font-weight: 100;
    margin-top: 30px;
}

.checkboxContainer h4 h5 {
    color: #b99855;
}
.checkboxContainer ul {
    list-style-type: none;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.checkboxContainer  h6 {
    color: #b99855;
    font-weight: 300;
}
.checkboxContainer input {
    margin-right: 30px;
}
.checkboxContainer li {
    font: 400 18px;
}
.checkboxContainer2  h6 {
    color: #b99855;
    font-weight: 300;
}
.checkboxContainer2 input {
    margin-right: 30px;
}
.checkboxContainer2 li {
    font: 400 18px;
}
.checkboxContainer2 h4 h5 {
    color: #b99855;
}
.checkboxContainer2 ul {
    list-style-type: none;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
/* .firstcheckbox{
    margin-left: 0 !important;
}
.secondcheckbox{
     margin-left: 0 !important;
}
.thirdcheckbox{
     margin-left: 0 !important;
} */
.arabiccheckbox{
    /* width: 100%; */
}
.rtl .arabiccheckbox{
    width: 80%;
}
.rtl .firstcheckbox{
     margin-left: 23px;
}
.rtl .secondcheckbox{
    margin-left: 14px;
}
.rtl .thirdcheckbox{
    margin-left: -2px;
}
#inactive {
    filter: brightness(20%);
}

.rtl .para{
    text-align: right;
  }
 
.validation-error-cbox{
    display: block;
    margin-top: 2px;
    font-size: 13px;
    color: #dc3545;
}  





 .remaining_amount_6th{
  width: 83%;
    text-align: right;
                    font-size: "12px";
                    width: "805px";
}

@media (width:768px) {
  .remaining_amount_6th{

    /* text-align: right; */
                    font-size: "12px";
                    width: 100%;
  
                    padding-right: "50px !important";
}
}
@media (width:320px) {
    .remaining_amount_6th{

    /* text-align: left; */
                    font-size: "12px";
                    width: 100%;

                    
                    padding-left: "20px !important";
}
}


.remaining_amount_5th{

    text-align: right;
                    font-size: "12px";
                    width: "805px";
}

@media (width:768px) {
  .remaining_amount_5th{

    /* text-align: right; */
                    font-size: "12px";
                    width: "805px";
                    padding-right: "50px !important";
}
}
@media (width:320px) {
    .remaining_amount_5th{

    /* text-align: left; */
                    font-size: "12px";
                    width: "805px";
                    padding-left: "20px !important";
}
}