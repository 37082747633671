.confirmation-modal .modal-content {
  /* background-color: #1a1a1a; */
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
   background-color: #212121;
}
.confirmation-modal .modal-body {
    padding: 0;
    color:white;
    font-weight: 400;
    padding: 50px;
    
}
.rtl .modalcontent {
  text-align: right !important;
}
