.custom-tooltip-container {
  padding: 15px 20px;
  width: 600px;
  line-height: 1.6;
  font-size: 14px;
  color: #fff;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80vh;
}

/* Tablet devices */
@media screen and (max-width: 768px) {
  .custom-tooltip-container {
    width: 450px;
    max-width: 90vw;
  }
}

/* Mobile devices */
@media screen and (max-width: 480px) {
  .custom-tooltip-container {
    width: 300px;
    max-width: 85vw;
    padding: 12px 15px;
    font-size: 10px;
  }
}

/* Small mobile devices */
@media screen and (max-width: 320px) {
  .custom-tooltip-container {
    width: 250px;
    max-width: 80vw;
    padding: 10px 12px;
  }
}

/* Override Bootstrap tooltip styles */
.tooltip {
  max-width: none !important;
}

.tooltip-inner {
  max-width: none !important;
  background-color: transparent !important;
  padding: 0 !important;
}
